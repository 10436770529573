import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { PageContextPropsType } from '../../../i18n/i18n'
import SEO from '../../components/SEO'
import Layout from '../../components/LayoutSeminar'
import Hero from '../../components/pages/seminar/Hero'
import Download from '../../components/pages/seminar/Download'
import Event from '../../components/pages/seminar/Event'

export default function SeminarEbook({ pageContext }: PageContextPropsType) {
  const allMicrocmsData = useStaticQuery(graphql`
    query {
      allMicrocmsEbookV2(filter: {page_path: { regex: "/blocks/" }}, sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            ebookV2Id
            thumbnail {
              url
            }
            subject
            is_new
            page_path
          }
        }
      }
      allMicrocmsSeminarEvent(
        filter: { hashtags: { elemMatch: { id: { in: "site_management" } } }, content_type: { ne: "event" } }
        sort: { fields: publishedAt, order: DESC }
      ) {
        edges {
          node {
            seminarEventId
            subject
            sub_subject
            start_time
            end_time
            content_type
            og_image {
              url
            }
          }
        }
      }
    }
  `)

  const { allMicrocmsEbookV2 } = allMicrocmsData
  const { allMicrocmsSeminarEvent } = allMicrocmsData
  return (
    <Layout pageContext={pageContext}>
      <SEO
        title="お役立ち資料 / セミナー | KARTE Blocks"
        description="KARTE Blocksのお役立ち資料 / セミナー情報を掲載しています。お気軽にKARTE Blocksによる新しいサイト運営をお試しいただくことが可能です。"
        path="/seminar-ebook/"
      />
      <Hero />
      <Download data={allMicrocmsEbookV2} />
      <Event data={allMicrocmsSeminarEvent} />
    </Layout>
  )
}
