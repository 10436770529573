import React from 'react'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EbookEdge, AllEbook } from '../../../types/seminar'
import * as DownloadStyles from '../../../styles/pages/seminar/Download.css'

type Props = {
  data: AllEbook
}

const Download: React.VFC<Props> = ({ data }) => {
  return (
    <section className={DownloadStyles.Container}>
      <div className={DownloadStyles.Inner}>
        <p className={DownloadStyles.SubHeadline}>Ebook</p>
        <h1 className={DownloadStyles.Headline}>
          <FontAwesomeIcon icon={faDownload} width={24} height={38} className={DownloadStyles.Icon} />
          資料ダウンロード
        </h1>
        <ul className={DownloadStyles.Catalog}>
          {data.edges.map((edge: EbookEdge, index: number) => (
            <li key={index}>
              <a href={edge.node.page_path} target="_blank" className={DownloadStyles.Catalog_Link}>
                <article>
                  <img
                    src={edge.node.thumbnail.url}
                    width="311"
                    height="175"
                    alt=""
                    loading="lazy"
                    className={DownloadStyles.Catalog_Image}
                  />
                  <div className={DownloadStyles.Catalog_Body}>
                    <h1 className={DownloadStyles.Catalog_Headline}>{edge.node.subject}</h1>
                    {edge.node.is_new ? <span className={DownloadStyles.Catalog_New}>New</span> : ''}
                  </div>
                </article>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Download
