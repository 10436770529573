import React from 'react'
import * as HomeNextActionStyles from '../styles/HomeNextAction.css'
import * as SeminarNextActionStyles from '../styles/pages/seminar/NextAction.css'
import className from 'classnames'
import { FormattedMessage } from 'react-intl'
import { formattedMessageValues } from '../utils/FormattedMessageValues'

type Props = {
  type?: string
}

const HomeNextAction: React.VFC<Props> = ({ type }: Props) => {
  return (
    <section className={className(HomeNextActionStyles.Container, type === 'seminar' && SeminarNextActionStyles.Container)}>
      {type !== 'seminar' && (
        <span className={HomeNextActionStyles.SmallHeadline}>
          <FormattedMessage id="pages.top.nextAction.eyebrow" defaultMessage="Contact" values={{ ...formattedMessageValues }} />
        </span>
      )}
      <h2 className={HomeNextActionStyles.Headline}>
        <FormattedMessage id="pages.top.nextAction.title" defaultMessage="導入のご相談" values={{ ...formattedMessageValues }} />
      </h2>
      <p className={HomeNextActionStyles.Lead}>
        <FormattedMessage
          id="pages.top.nextAction.lead"
          defaultMessage="お気軽にご質問・ご相談ください"
          values={{ ...formattedMessageValues }}
        />
      </p>
      <ul className={HomeNextActionStyles.Listing}>
        <li className={HomeNextActionStyles.Listing_Item}>
          <div className={HomeNextActionStyles.Listing_Item_Inner}>
            <img
              src="/assets/images/home/contact-download.svg"
              width="32"
              height="30"
              alt=""
              className={HomeNextActionStyles.Listing_Icon}
            />
            <p className={HomeNextActionStyles.Liting_Title}>
              <FormattedMessage id="pages.top.nextAction.text1" defaultMessage="資料ダウンロード" values={{ ...formattedMessageValues }} />
            </p>
          </div>
          <a
            href="https://karte.io/resource/ebook/"
            target="_blank"
            rel="noreferrer noopener"
            className={HomeNextActionStyles.Listing_Link}>
            <span className={HomeNextActionStyles.Listing_Inner}>
              <FormattedMessage id="pages.top.nextAction.button1" defaultMessage="一覧を見る" values={{ ...formattedMessageValues }} />
            </span>
            <svg width="14" height="12" viewBox="0 0 14 12">
              <path d="M8.094.691l4.812 4.594c.137.137.219.3.219.492a.674.674 0 01-.219.465l-4.812 4.594a.653.653 0 01-.93-.027.653.653 0 01.027-.93l3.637-3.445H1.531a.648.648 0 01-.656-.657c0-.382.273-.656.656-.656h9.297L7.191 1.648a.653.653 0 01-.027-.93c.246-.273.656-.273.93-.027z" />
            </svg>
          </a>
        </li>
        <li className={HomeNextActionStyles.Listing_Item}>
          <div className={HomeNextActionStyles.Listing_Item_Inner}>
            <img src="/assets/images/home/contact-mail.svg" width="32" height="30" alt="" className={HomeNextActionStyles.Listing_Icon} />
            <p className={HomeNextActionStyles.Liting_Title}>
              <FormattedMessage
                id="pages.top.nextAction.text2"
                defaultMessage="メールでお問い合わせ"
                values={{ ...formattedMessageValues }}
              />
            </p>
          </div>
          <a href="https://karte.io/enterprise/" target="_blank" rel="noreferrer noopener" className={HomeNextActionStyles.Listing_Link}>
            <span className={HomeNextActionStyles.Listing_Inner}>
              <FormattedMessage
                id="pages.top.nextAction.button2"
                defaultMessage="フォームから送信"
                values={{ ...formattedMessageValues }}
              />
            </span>
            <svg width="14" height="12" viewBox="0 0 14 12">
              <path d="M8.094.691l4.812 4.594c.137.137.219.3.219.492a.674.674 0 01-.219.465l-4.812 4.594a.653.653 0 01-.93-.027.653.653 0 01.027-.93l3.637-3.445H1.531a.648.648 0 01-.656-.657c0-.382.273-.656.656-.656h9.297L7.191 1.648a.653.653 0 01-.027-.93c.246-.273.656-.273.93-.027z" />
            </svg>
          </a>
        </li>
        <li className={HomeNextActionStyles.Listing_Item}>
          <div className={HomeNextActionStyles.Listing_Item_Inner}>
            <img
              src="/assets/images/home/contact-reserve.svg"
              width="32"
              height="30"
              alt=""
              className={HomeNextActionStyles.Listing_Icon}
            />
            <p className={HomeNextActionStyles.Liting_Title}>
              <FormattedMessage id="pages.top.nextAction.text3" defaultMessage="オンライン商談" values={{ ...formattedMessageValues }} />
            </p>
          </div>
          <a
            href="https://app.dr.works/plaid/karte_blocks_onlinemeeting"
            target="_blank"
            rel="noreferrer noopener"
            className={HomeNextActionStyles.Listing_Link}>
            <span className={HomeNextActionStyles.Listing_Inner}>
              <FormattedMessage id="pages.top.nextAction.button3" defaultMessage="予約する" values={{ ...formattedMessageValues }} />
            </span>
            <svg width="14" height="12" viewBox="0 0 14 12">
              <path d="M8.094.691l4.812 4.594c.137.137.219.3.219.492a.674.674 0 01-.219.465l-4.812 4.594a.653.653 0 01-.93-.027.653.653 0 01.027-.93l3.637-3.445H1.531a.648.648 0 01-.656-.657c0-.382.273-.656.656-.656h9.297L7.191 1.648a.653.653 0 01-.027-.93c.246-.273.656-.273.93-.027z" />
            </svg>
          </a>
        </li>
      </ul>
    </section>
  )
}

export default HomeNextAction
