import React from 'react'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as HomeBannerStyles from '../styles/HomeBanner.css'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import { formattedMessageValues } from '../utils/FormattedMessageValues'

const HomeBanner: React.VFC = () => {
  return (
    <section className={HomeBannerStyles.Container}>
      <div className={HomeBannerStyles.Inner}>
        <h2 className={HomeBannerStyles.Headline}>
          <FormattedMessage
            id="pages.top.banner.title"
            defaultMessage="もっと自由で、快適に。{br}あなたのWebサイトの価値{brMobile}を高めましょう"
            values={{ ...formattedMessageValues }}
          />
        </h2>
        <div className={HomeBannerStyles.Listing}>
          <div className={HomeBannerStyles.Listing_Item}>
            <span className={HomeBannerStyles.Listing_Slogan}>
              <FormattedMessage id="pages.top.banner.button1.eyebrow" defaultMessage="3分でわかる" values={{ ...formattedMessageValues }} />
            </span>
            <a href="https://blocks.karte.io/ebook/" target="_blank" className={HomeBannerStyles.Listing_Button}>
              <span className={HomeBannerStyles.Listing_Button_Text}>
                <FormattedMessage id="pages.top.banner.button1" defaultMessage="資料ダウンロード" values={{ ...formattedMessageValues }} />
              </span>
              <svg width="14" height="13" viewBox="0 0 14 13" fill="#12a0a0" className={HomeBannerStyles.Listing_Icon}>
                <path d="M8.25.908l5.5 5.25c.156.156.25.344.25.563 0 .187-.094.375-.25.53l-5.5 5.251a.746.746 0 01-1.063-.031.746.746 0 01.032-1.063l4.156-3.937H.75A.74.74 0 010 6.72c0-.438.313-.75.75-.75h10.625L7.219 2.002A.746.746 0 017.187.939C7.47.627 7.938.627 8.25.91z" />
              </svg>
            </a>
          </div>
          <div className={cx(HomeBannerStyles.Listing_Item, SiteWideStyles.DisplayDesktopOnly)}>
            <span className={HomeBannerStyles.Listing_Slogan}>
              <FormattedMessage
                id="pages.top.banner.button2.eyebrow"
                defaultMessage="あなたのサイトで"
                values={{ ...formattedMessageValues }}
              />
            </span>
            <a href="https://karte.io/enterprise/blocks/trial.html" target="_blank" className={HomeBannerStyles.Listing_Button}>
              <span className={HomeBannerStyles.Listing_Button_Text}>
                <FormattedMessage id="pages.top.banner.button2" defaultMessage="デモを体感" values={{ ...formattedMessageValues }} />
              </span>
              <svg width="14" height="13" viewBox="0 0 14 13" fill="#12a0a0" className={HomeBannerStyles.Listing_Icon}>
                <path d="M8.25.908l5.5 5.25c.156.156.25.344.25.563 0 .187-.094.375-.25.53l-5.5 5.251a.746.746 0 01-1.063-.031.746.746 0 01.032-1.063l4.156-3.937H.75A.74.74 0 010 6.72c0-.438.313-.75.75-.75h10.625L7.219 2.002A.746.746 0 017.187.939C7.47.627 7.938.627 8.25.91z" />
              </svg>
            </a>
          </div>
        </div>
        <img src="/assets/images/home/banner-background-left.svg" alt="" className={HomeBannerStyles.Background_Left} />
        <img src="/assets/images/home/banner-background-right.svg" alt="" className={HomeBannerStyles.Background_Right} />
        <img src="/assets/images/home/banner-background-mobile.svg" alt="" className={HomeBannerStyles.Background_Mobile} />
      </div>
    </section>
  )
}

export default HomeBanner
