import React, { useCallback } from 'react'
import classNames from 'classnames'
import { DateTime, Settings } from 'luxon'
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SeminarEventEdge, AllSeminarEvent } from '../../../types/seminar'
import * as EventStyles from '../../../styles/pages/seminar/Event.css'

type Props = {
  data: AllSeminarEvent
}

const Event: React.VFC<Props> = ({ data }) => {
  Settings.defaultLocale = 'ja'

  const isBeforeEndTime = useCallback((edge) => {
    if (!edge.node.end_time) return true
    const currentTime = DateTime.now()
    const endTime = DateTime.fromISO(edge.node.end_time)
    return currentTime.toUnixInteger() < endTime.toUnixInteger()
  }, [])

  const edges = data.edges.filter(isBeforeEndTime)

  return (
    <section className={EventStyles.Container}>
      <div className={EventStyles.Inner}>
        <p className={EventStyles.Eyebrow}>Seminars & Events</p>
        <h1 className={EventStyles.Headline}>
          <FontAwesomeIcon icon={faCalendarDay} width={24} height={38} className={EventStyles.Icon} />
          セミナー・イベント
        </h1>
        <ul className={EventStyles.Catalog}>
          {edges.slice(0, 10).map((edge: SeminarEventEdge, index: number) => (
            <li key={index} className={classNames(EventStyles.Catalog_Banner, index === 0 && 'is-first', edges.length !== 1 && 'is-top')}>
              <a
                href={`https://karte.io/seminar/${edge.node.content_type}/${edge.node.seminarEventId}/`}
                target="_blank"
                className={EventStyles.Catalog_Link}
              >
                <article className={classNames(EventStyles.Catalog_Inner, index === 0 && 'is-first')}>
                  <img src={edge.node.og_image.url} width="311" height="175" alt="" loading="lazy" className={EventStyles.Catalog_Image} />
                  <div className={EventStyles.Catalog_Body}>
                    {index === 0 && <p className={EventStyles.Catalog_Eyebrow}>{edge.node.sub_subject}</p>}
                    <h1 className={EventStyles.Catalog_Headline}>{edge.node.subject}</h1>
                    <p className={EventStyles.Catalog_Summary}>
                      {edge.node.start_time
                        ? `${DateTime.fromISO(edge.node.start_time)
                            .setZone('Asia/Tokyo')
                            .toFormat('yyyy/M/d（EEE） HH:mm')} - ${DateTime.fromISO(edge.node.end_time)
                            .setZone('Asia/Tokyo')
                            .toFormat('HH:mm')}`
                        : edge.node.content_type == 'on-demand' && 'オンデマンド配信中'}
                    </p>
                  </div>
                </article>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Event
