import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as HeroStyles from '../../../styles/pages/seminar/Hero.css'

const Hero: React.VFC = () => {
  const heroImage = useStaticQuery(graphql`
    query {
      squishy: file(relativePath: { eq: "site-wide/squishy_texture.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <section className={HeroStyles.Container}>
      <div className={HeroStyles.Frame}>
        <GatsbyImage image={heroImage.squishy.childImageSharp.gatsbyImageData} alt="" className={HeroStyles.Image} />
      </div>
      <div className={HeroStyles.Inner}>
        <div className={HeroStyles.Head}>
          <h1 className={HeroStyles.Headline}>
            KARTE Blocks <span className={HeroStyles.Line}>お役立ち資料 / セミナー</span>
          </h1>
        </div>
      </div>
    </section>
  )
}

export default Hero
